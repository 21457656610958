<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="order"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Fecha:
                  </p>
                  <p class="invoice-date">
                    {{ order.created_at | fullDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Hora:
                  </p>
                  <p class="invoice-date">
                    {{ order.created_at | time }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Método de pago:
                  </p>
                  <p class="invoice-date">
                    {{ order.order_payments_attributes[0].payment_type | paymentType }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    ¿Es fiado?:
                  </p>
                  <p class="invoice-date">
                    {{ order.is_loan === true ? 'Si':'No' }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <div v-if="order.is_refund === true && order.delivery_status === 'cancelled_by_customer'">
            <b-card style="color:red;" no-body class="text-center">
              <div class="bg-danger text-light">
                Esta compra ha sido cancelada por el cliente
              </div>
            </b-card>
          </div>

          <div v-if="order.is_refund === true && order.delivery_status === 'cancelled_by_establishment'">
            <b-card style="color:red;" no-body class="text-center">
              <div class="bg-danger text-light">
                Esta compra ha sido cancelada por administrador del negocio
              </div>
            </b-card>
          </div>

          <div v-if="order.delivery_status === 'rejected_by_dispatcher' && userData.role_name === 'dispatcher'">
            <b-card style="color:red;" no-body class="text-center">
              <div class="bg-danger text-light">
                Rechazaste esta entrega
              </div>
            </b-card>
          </div>

          <div v-if="order.delivery_status === 'delivered' && order.order_mode === 'online' ">
            <b-card style="color:red;" no-body class="text-center">
              <div class="bg-success text-light">
                Compra entregada
              </div>
            </b-card>
          </div>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Description: Table for store_products -->
          <b-table-lite
            v-if="order.order_store_products_attributes.length > 0"
            responsive
            :items="order.order_store_products_attributes"
            :fields="[{key:'name', label: 'producto'}, {key: 'unit_price', label: 'precio'}, {key: 'units', label: 'unidades'}, 'total']"
          >
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25 d-flex">
                <b-img
                  width="40px"
                  height="40px"
                  :src="`${data.item.store_product_attributes.product_attributes.logo}`"
                  rounded
                  class="mr-1"
                />
                <div>
                  {{ data.item.store_product_attributes.product_attributes.name }}
                  <div>
                    <b-badge
                      v-for="category in data.item.store_product_attributes.product_attributes.categories_names"
                      :key="`category-${category}`"
                      variant="light-primary"
                    >
                      {{ category }}
                    </b-badge>
                  </div>
                </div>
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Emida products table -->
          <table
            v-if="order.emida_response"
            class="table-responsive table b-table"
          >
            <thead style="background-color: #343d55;">
              <th>PRODUCTO</th>
              <th>REFERENCIA</th>
              <th>CÓDIGO DE AUTORIZACIÓN</th>
            </thead>
            <tr>
              <td>
                <div>
                  {{ JSON.parse(order.emida_response.request).productName }}
                  <div>
                    <p class="font-weight-bold">
                      Nota:
                      <span class="text-muted">
                        {{ order.emida_response.response_message }}
                      </span>
                    </p>
                  </div>
                </div>
              </td>
              <td>{{ order.emida_response.pin }}</td>
              <td>{{ order.emida_response.carrier_control_no }}</td>
            </tr>
          </table>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <!-- Total div for service payment -->
                <div
                  v-if="order.emida_response && order.emida_response.flow_type === 'b'"
                  class="invoice-total-wrapper"
                >
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ (Number(order.total) - Number(JSON.parse(order.emida_response.request).fee)) | money }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Comisión:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ JSON.parse(order.emida_response.request).fee | money }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ order.total | money }}
                    </p>
                  </div>
                </div>
                <!-- Total div for the other type of orders -->
                <div
                  v-else
                  class="invoice-total-wrapper"
                >
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ order.subtotal | money }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      IVA:
                    </p>
                    <p class="invoice-total-amount">
                      $ {{ order.iva | money }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ order.total | money }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Note -->
          <b-card-body class="invoice-padding pt-3">
            <span class="font-weight-bold">Nota: </span>
            <span>Gracias por tu preferencia! ✌</span>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
/* eslint-disable */
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton,
  BAlert, BLink, VBToggle, BImg, BBadge,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { $themeConfig } from '@themeConfig'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    Logo,
    BBadge,
  },
  props: {
    orderId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const {  appLogoImage } = $themeConfig.app

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      appLogoImage,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      order: undefined,
      locationLink: null,
    }
  },
  beforeMount() {
    this.fetchOrder(this.orderId)
      .then(response => {
        this.order = response
      })
  },
  methods: {
    ...mapActions('orders', ['fetchOrder', 'sellerCancelsOnlineOrder', 'cancelOnlineOrder', 'deliveryConfirmation', 'confirmedByDispatcher', 'rejectedByDispatcher']),
    ...mapActions('printer', ['printEmidaInvoice']),
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    dispatcherConfirms() {
      this.locationLink = 'https://www.google.com/maps/search/?api=1&query=' + `${this.order.delivery_location.lat}` + '%2C' + `${this.order.delivery_location.lng}`
      this.confirmedByDispatcher({
        id: this.order.id,
      })
        .then(response => {
          this.$swal({
            title: 'Orden aceptada!',
            icon: 'success',
            customClass: {
              confirmButton: 'btnx btn-primary',
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id)
            .then(response => {
              this.order = response
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    dispatcherRejects() {
      this.rejectedByDispatcher({
        id: this.order.id,
      })
        .then(response => {
          this.$swal({
            title: 'Orden rechazada!',
            icon: 'success',
            customClass: {
              confirmButton: 'btnx btn-primary',
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id)
            .then(response => {
              this.order = response
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    dispatcherConfirmsDelivery() {
      this.deliveryConfirmation({
        id: this.order.id,
      })
        .then(response => {
          this.$swal({
            title: 'Orden marcada como entrega!',
            icon: 'success',
            customClass: {
              confirmButton: 'btnx btn-primary',
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id)
            .then(response => {
              this.order = response
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    cancelOrderByEstablishment() {
      this.sellerCancelsOnlineOrder({id: this.order.id})
        .then(response => {
          this.$swal({
            title: 'Orden cancelada!',
            icon: 'success',
            customClass: {
              confirmButton: 'btnx btn-primary',
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id)
            .then(response => {
              this.order = response
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    cancelOrderByCustomer() {
      this.cancelOnlineOrder(this.order)
        .then(response => {
          this.$swal({
            title: 'Orden cancelada!',
            icon: 'success',
            customClass: {
              confirmButton: 'btnx btn-primary',
            },
            buttonsStyling: false,
          })
          this.fetchOrder(this.$route.params.id)
            .then(response => {
              this.order = response
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages[0],
            },
          })
        })
    },
    printInvoice() {
      if (this.order.order_type === 'emida') {
          if (JSON.parse(localStorage.getItem('print')) === true) {
            const parsedRequest = JSON.parse(this.order.emida_response.request)
            const orderToPrint = {
              order_type: {
                payment: this.order.order_payments_attributes[0].payment_type,
                type: 'sell',
              },
              store: this.eliminarDiacriticos(this.order.store.name),
              products: {
                name: parsedRequest.productName,
                important_info: this.order.emida_response.response_message,
                autorization_code: this.order.emida_response.carrier_control_no,
                refence: this.order.emida_response.pin,
                unit_price: Number(this.order.total).toFixed(2),
              },
              total: Number(this.order.total),
              date: Date.now(this.order.created_at),

            }
            this.printEmidaInvoice({ ...orderToPrint })
          }
      } else {
        window.print()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  #logo {
    filter: invert(1);
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }

  .hide-print,
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
