<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search by folio -->
        <b-col cols="12">
          <span class="mr-1">Buscar por folio</span>
          <b-form-input
            id="v-folio"
            v-model="folioFilter"
            placeholder="1234"
            size="lg"
            type="text"
          />
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="orders"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
      small
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(folio)="data">
        <b-link
          :to="{ name: 'order-view', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.item.auto_inc_folio }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Issued Date -->
      <!-- 
      <template #cell(created_at)="data">
        <span class="text-nowrap mr-2">
          {{ data.value | dateNtime }}
        </span>
      </template>
      -->

      <!-- Column: Total -->
      <template #cell(total)="data">
        <div>
          ${{ data.value | money }}
        </div>
      </template>

      <!-- Column: Order type -->
      <template #cell(order_type)="data">
        <template>
          <b-badge
            v-if="data.item.is_refund === false"
            pill
            :variant="data.value === 'sell' ? 'light-success' : 'light-info'"
          >
            <feather-icon
              :icon="data.item.order_mode | orderModeIcon"
              :class="data.item.order_mode | orderModeClass"
              class="mr-1"
            />
            {{ data.item.order_type | orderType }}
          </b-badge>
          <b-badge
            v-else-if="data.item.is_refund === true"
            pill
            :variant="'light-warning'"
          >
            <feather-icon
              :icon="data.item.order_mode | orderModeIcon"
              :class="data.item.order_mode | orderModeClass"
              class="mr-1"
            />
            {{ "Devolución" }}
          </b-badge>
        </template>
      </template>

      <!-- Column: Payment type -->
      <!--<template #cell(order_payments_attributes)="data">
        <template>
          <b-badge
            pill
            :variant="data.item.order_payments_attributes[0].payment_type === 'cash' ? 'light-success' : data.item.order_payments_attributes[0].payment_type === 'walleat' || data.item.order_payments_attributes[0].payment_type === 'reward_points' ? 'light-info' : 'light-warning'"
          >
            {{ data.item.order_payments_attributes[0].payment_type | paymentType }}
          </b-badge>
          <b-badge
            v-if="data.item.is_loan === true"
            pill
            :variant="'light-warning'"
          >
            {{ Fiado }}
          </b-badge>
        </template>
      </template>
      -->
      <!-- Column: Actions -->
      <template #cell(show_details)="row">
        <b-button size="sm" :variant="'primary'" @click="row.toggleDetails" class="mr-2">
          <small>
            {{ row.detailsShowing ? 'Ocultar' : 'Ver'}}
          </small>
        </b-button>
      </template>

      <template #row-details="data">
        <b-card>
          <b-row class="mb-2">
            <div class="d-flex justify-content-center">
              <InvoiceReducedDetail :orderId="data.item.id"/>
            </div>
          </b-row>
        </b-card>
      </template>
      
      <template #cell(tools)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'order-view', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Ver detalles de la venta"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <label># de resultados</label>
          <v-select
            v-model="entriesPerPageForCustomerOrders"
            :dir="'ltr'"
            :options="perPageForCustomerOrders"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(value)=>{handlePaginationForCustomerOrders({ page: value, per_page: pagination.per_page })}"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <b-spinner
                v-if="loading"
                small
                label="Loading"
              />
              <feather-icon
                v-else
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InvoiceReducedDetail from '@core/components/InvoiceReducedDetail.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  // BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BSpinner,
    InvoiceReducedDetail,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
    customer: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      entriesPerPageForCustomerOrders: '10',
      searchQuery: '',
      statusFilter: '',
      folioFilter: '',
      statusOptions: [
        'Todos',
        'Walleats',
        'Efectivo',
        'Tarjetas bancarias',
      ],
      tableColumns: [
        'folio',
        /* 
        {
          key: 'created_at',
          label: 'Fecha',
        },
        */
        'total',
        {
          key: 'order_type',
          label: 'Tipo de orden',
        },
        /* 
        {
          key: 'order_payments_attributes',
          label: 'Tipo de pago',
        },
        */
        {
          key: 'show_details',
          label: 'Ver detalles',
        },
      ],
      perPageForCustomerOrders: ['10', '20', '50', '100'],
    }
  },
  computed: {
    ...mapGetters('orders', [
      'pagination',
      'orders',
    ]),
  },
  watch: {
    entriesPerPageForCustomerOrders() {
      this.loading = true
      this.fetchOrders({
        by_customer: this.customer,
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPageForCustomerOrders,
          },
        },
      }).then(() => {
        this.loading = false
        this.$bvModal.show('view-orders-modal')
      })
    },
    folioFilter() {
      this.loading = true
      this.fetchOrders({
        by_customer: this.customer,
        by_auto_folio: this.folioFilter,
      })
        .then(() => {
          this.loading = false
        })
    },
  },
  methods: {
    ...mapActions('orders', [
      'fetchOrders',
    ]),
    handlePaginationForCustomerOrders({ page, per_page }) {
      let dates
      if (this.dates) {
        dates = {
          start_date: Array.sisArray(this.dates) ? this.dates[0] : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates) ? this.dates[1] : this.dates.substring(14),
        }
      } else {
        dates = null
      }
      this.loading = true
      this.fetchOrders({
        by_customer: this.customer,
        by_date: dates,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
        .then(() => {
          this.loading = false
          this.$bvModal.show('view-orders-modal')
        })
    },
    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    formatFirstDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = '1'
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input{
  max-width: 200px;
}
</style>
