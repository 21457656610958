<template>
  <div>
    <div
      v-if="userImg && enc"
      class="d-flex justify-content-center mt-2 mb-1"
    >
      <b-img
        thumbnail
        rounded
        :src="userImg"
        width="200"
      />
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center">
      <div
        v-if="!walleatName"
        class="p-5"
      >
        <i class="fas fa-spinner fa-spin fa-3x" />
      </div>
      <b-badge
        v-if="walleatName"
        variant="light-warning my-1"
      >
        {{ walleatName }}
      </b-badge>
    </div>

    <div
      v-if="enc && walleatName"
      class="d-flex flex-column"
    >
      <validation-observer
        ref="amountForm"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              ¿Cuánto deseas abonar a la deuda?
            </h5>
            <small class="text-muted">
              Esto es un abono a la deuda del brazalete
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label-for="v-ammount"
            >
              <validation-provider
                #default="{ errors }"
                name="Cantidad a abonar"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      :state="errors.length > 0 ? false:null"
                    >
                      <strong :class="errors.length > 0 ? 'text-danger' : null">$</strong>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="v-ammount"
                    v-model="total"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="25"
                    size="lg"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <b-button-group
        class="w-100"
      >
        <b-button
          variant="danger"
          @click="cancelSale()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="success"
          @click="completeSale()"
        >
          Continuar
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import {
  BButton, BImg, BButtonGroup, BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
  components: {
    BButton,
    BImg,
    BButtonGroup,
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
      nfcStatus: null,
      userImg: null,
      walleatName: null,
      total: null,
      bannedItems: [],
    }
  },
  computed: {
    ...mapGetters('nfcService', ['enc']),
    ...mapGetters('stores', ['getStoreIdForLoanPayment']),
  },
  watch: {
    enc: debounce(function searchQuery(query) {
      if (query) {
        this.fetchWalleatByEnc(query).then(response => {
          this.userImg = response.data.logo
          this.walleatName = response.data.name
        })
      } else {
        this.userImg = null
        this.walleatName = null
      }
    }, 250),
  },
  mounted() {},
  methods: {
    ...mapActions('walleats', ['fetchWalleatByEnc']),
    ...mapActions('orders', ['dispatchLoanOrder']),
    ...mapMutations('nfcService', ['SET_ENC']),
    ...mapMutations('pos', ['SET_LOAN_PAYMENT']),
    ...mapActions('printer', ['print']),
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    completeSale() {
      this.$refs.amountForm.validate().then(success => {
        if (success) {
          const orderReady = {
            payment_type: 'cash',
            store_id: this.$route.params.store_id? this.$route.params.store_id : this.getStoreIdForLoanPayment,
            store_clerk_id: this.userData.id, // TODO: Check if it's obligatory to send it
            bracelet_number: this.enc,
            total: this.total,
            order_payments_attributes: [
              {
                payment_type: 'cash',
                amount: this.total
              },
            ],
          }

          this.dispatchLoanOrder(orderReady)
            .then(response => {
              this.SET_ENC(null)
              // eslint-disable-next-line
              const audio = new Audio(require("@/assets/sounds/Success.wav"))
              audio.play()
              this.$swal({
                title: 'Abono exitoso!',
                text: 'Gracias.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })

              if (JSON.parse(localStorage.getItem('print')) === true) {
                const orderToPrint = {
                  order_type: {
                    payment: 'walleat',
                    type: 'sell',
                  },
                  store: this.eliminarDiacriticos(response.store.name),
                  products: {
                    name: 'Pago de abono a deuda',
                    variant: '',
                    units: 1,
                    unit_price: response.total,
                  },
                  subtotal: response.subtotal,
                  iva: response.iva,
                  total: response.total,
                  date: new Date(response.created_at),

                }

                this.print({ ...orderToPrint })
              }
              this.walleatName = null
              this.userImg = null
              this.SET_LOAN_PAYMENT(false)
              this.cancelSale()
            })
            .catch(error => {
              // eslint-disable-next-line
              const audio = new Audio(require("@/assets/sounds/Denied.wav"))
              audio.play()
              if (navigator.vibrate) {
                navigator.vibrate(200)
              }
              this.SET_LOAN_PAYMENT(false)
              if (error.response.data.messages) {
                this.$swal({
                  title: 'ERROR!',
                  text: error.response.data.messages[0],
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
              this.cancelSale()
            })
        }
      })
    },
    cancelSale() {
      this.walleatName = null
      this.userImg = null
      this.SET_ENC(null)
      this.$bvModal.hide('nfc-loan-modal')
      this.SET_LOAN_PAYMENT(false)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
